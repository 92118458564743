<template>
	<span>
		<i v-if="!noIcon" class="fas fa-question-circle"></i>

		<div class="tooltip" :style="large && 'width: 420px'">
			<span class="tooltip--text">
				<slot />
			</span>
		</div>
	</span>
</template>

<script>
export default {
	name: 'Tooltip',

	props: {
		visible: Boolean,
		value: Object,
		large: Boolean,
		noIcon: Boolean,
	},
};
</script>

<style lang="scss" scoped>
.tooltip {
	position: absolute;
	left: 50%;
	bottom: 100%;

	margin-bottom: 8px;

	transform: translateX(-50%);

	width: 256px;

	padding: 8px;

	color: white;
	background: #3f3f42;

	border-radius: 5px;

	pointer-events: none;

	box-shadow: 1px 3px 5px rgba(black, 0.1);

	.tooltip--text {
		position: relative;

		font-size: 14px;
		font-weight: 400;

		z-index: 10;
	}

	&::before {
		content: '';

		position: absolute;
		bottom: -5px;
		left: 50%;

		transform: translateX(-50%) rotate(45deg);
		background: #3f3f42;

		width: 12px;
		height: 12px;

		z-index: 9;
	}
}
</style>
