<template>
	<v-overlay :visible="visible" @close="$emit('close')">
		<h1 class="mb-10">Terminalar</h1>

		<p class="mb-30 color-grey">Být um terminal</p>

		<div v-if="oldTerminal" class="block w-100 py-20 px-30 mb-20 border-light rounded-lg hover:background-ultra-light-grey-300">
			<div class="flex space-between align-center">
				<div>
					<div class="color-black heavy">Verifone {{ oldTerminal.verifoneSerialnumber }}</div>

					<div class="color-grey">{{ oldTerminal.phoneNumber }}</div>
				</div>
			</div>
		</div>

		<div v-if="oldTerminal" class="flex justify-center mb-20">
			<i class="fas fa-chevron-down fa-lg color-grey"></i>
		</div>

		<div v-if="newTerminal" class="block w-100 py-20 px-30 mb-20 border-light rounded-lg hover:background-ultra-light-grey-300">
			<div class="flex space-between align-center">
				<div>
					<div class="color-black heavy">Verifone {{ newTerminal.verifoneSerialnumber }}</div>

					<div class="color-grey">{{ newTerminal.phoneNumber }}</div>
				</div>

				<div class="flex">
					<div class="color-red cursor-pointer" @click="resetNewTerminal()">Strika</div>
				</div>
			</div>
		</div>

		<div class="mb-20" v-if="!newTerminal">
			<label class="block mb-10" for="search-input-name">Leita eftir nýggjum terminali</label>

			<div class="search-input relative" :class="{ 'z-100': searchString.length > 0 }">
				<input type="text" v-model="searchString" @keyup="search" @keydown.esc.prevent="searchString = ''" />

				<div class="icon-search"></div>

				<div
					v-if="!newTerminal && searchString.length > 0 && searchResults.length > 0"
					class="absolute w-100 flex flex-column mt-10 rounded background-white border-light py-10"
					:class="{ 'z-100': searchString.length > 0 }"
				>
					<div
						class="flex flex-column py-10 px-20 border-bottom hover:background-light-grey-100 last-child:border-none cursor-pointer"
						v-for="(searchResult, index) in searchResults"
						:key="index"
						@click="chooseNewTerminal(searchResult)"
					>
						<p class="mb-5">Verifone # {{ searchResult.verifoneNumber }} (S/N: {{ searchResult.verifoneSerialnumber }})</p>

						<p class="color-grey">Telefon # {{ searchResult.phoneNumber }}</p>

						<p class="color-grey">PSAM: {{ searchResult.psam }}</p>

						<p class="color-grey">ICC # {{ searchResult.icc }}</p>
					</div>
				</div>

				<div
					v-if="searchString.length > 0 && searchResults.length == 0"
					class="absolute w-100 flex flex-column mt-10 rounded background-white border-light py-10"
					:class="{ 'z-100': searchString.length > 0 }"
				>
					<div class="flex flex-column py-10 px-20 border-bottom hover:background-light-grey-100 last-child:border-none">
						<p class="mb-5">Ongin terminalur funnin</p>

						<p>
							Vit hava leitað eftir <strong>{{ searchString }}</strong>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div v-if="oldTerminal && newTerminal && oldTerminal.id == newTerminal.id" class="mt-10 color-red text-center">Hetta er sami terminalur</div>

		<div class="flex justify-end">
			<div class="btn" :class="{ disabled: !newTerminal }" @click="swapTerminals()">Být um</div>
		</div>
	</v-overlay>
</template>

<script>
import VOverlay from '@/components/VOverlay.vue';

import axios from 'axios';

export default {
	name: 'SwapTerminalOverlay',

	props: {
		visible: Boolean,
		oldTerminal: Object,
		agreement: Object,
	},

	components: {
		VOverlay,
	},

	data() {
		return {
			searchString: '',

			searchResults: [],

			terminals: [],

			newTerminal: null,
		};
	},

	methods: {
		search() {
			axios.get(`/terminals/search?q=${this.searchString}`).then((response) => {
				this.searchResults = response.data;
			});
		},

		chooseNewTerminal(terminal) {
			this.searchString = '';

			this.newTerminal = terminal;
		},

		resetNewTerminal() {
			this.newTerminal = null;
		},

		swapTerminals() {
			if (!this.newTerminal) {
				return false;
			}

			axios.post(`/agreements/${this.agreement.id}/swap-terminal/${this.oldTerminal.id}/${this.newTerminal.id}`).then((response) => {
				this.$emit('updateAgreementTerminals', response.data);
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.trash {
	&:hover {
		color: var(--color-red);
	}
}
</style>
