<template>
	<v-overlay :visible="visible" @close="close">
		<div class="flex space-between align-center mb-50">
			<div>
				<h1 class="mb-10">Tíðarskeið</h1>
			</div>

			<div
				class="overlay-close"
				@click.prevent="
					$v.$reset();
					close();
				"
			></div>
		</div>

		<div class="grid grid-2 grid-gap-20 mb-20">
			<v-date-picker v-model="agreementTerminal.from" mode="dateTime" is24hr :minute-increment="5" v-if="agreementTerminal">
				<template #default="{ inputValue, inputEvents }">
					<div>
						<label class="block mb-10" for="from">Avtala byrjar</label>

						<div class="date-input">
							<input type="text" :value="inputValue" v-on="inputEvents" />

							<div class="date-input-icon-now" title="Nú" @click="agreementTerminal.from = new Date()"><i class="fa fa-clock"></i></div>

							<div class="date-input-icon" v-if="!agreementTerminal.from"><i class="fa fa-calendar-alt"></i></div>

							<div class="date-input-icon" v-if="agreementTerminal.from" @click="agreementTerminal.from = null"><i class="fas fa-times"></i></div>
						</div>
					</div>
				</template>
			</v-date-picker>

			<v-date-picker
				v-model="agreementTerminal.to"
				mode="dateTime"
				is24hr
				:minute-increment="5"
				:min-date="agreementTerminal.from ? agreementTerminal.from : new Date()"
				v-if="agreementTerminal"
			>
				<template #default="{ inputValue, inputEvents }">
					<div>
						<label class="block mb-10" for="from">Avtala endar</label>

						<div class="date-input">
							<input type="text" :value="inputValue" v-on="inputEvents" />

							<div class="date-input-icon-now" title="Nú" @click="agreementTerminal.to = new Date()"><i class="fa fa-clock"></i></div>

							<div class="date-input-icon" v-if="!agreementTerminal.to"><i class="fa fa-calendar-alt"></i></div>

							<div class="date-input-icon" v-if="agreementTerminal.to" @click="agreementTerminal.to = null"><i class="fas fa-times"></i></div>
						</div>
					</div>
				</template>
			</v-date-picker>
		</div>

		<div v-if="dateOverlapsWith()" class="color-red text-center">
			Hetta yvirlappar við

			<router-link :to="{ name: 'AgreementShow', params: { id: dateOverlapsWith().id } }" class="color-red" style="font-weight: 700"
				>hesa avtaluna</router-link
			>.
		</div>

		<div class="flex justify-end">
			<div class="btn inline-block" :class="{ disabled: loading }" @click="edit">{{ loading ? 'Bíða við...' : 'Rætta' }}</div>
		</div>
	</v-overlay>
</template>

<script>
import { email } from 'vuelidate/lib/validators';
import VOverlay from '@/components/VOverlay.vue';

import axios from 'axios';

export default {
	name: 'EditTerminalPeriodOverlay',

	props: {
		visible: Boolean,
		agreementTerminal: Object,
	},

	components: {
		VOverlay,
	},

	data() {
		return {
			loading: false,
			terminal: null,
		};
	},

	watch: {
		agreementTerminal: function (agreementTerminal) {
			if (!agreementTerminal) {
				return;
			}

			axios
				.get(`/terminals/${this.agreementTerminal.terminal.id}`)
				.then((response) => {
					this.terminal = response.data;
				})
				.catch((error) => {
					console.error('Failed to tech terminal', error.response);
				});
		},
	},

	methods: {
		dateOverlaps(startA, endA, startB, endB) {
			if (startA <= startB && startB <= endA) {
				return true;
			}

			if (startA <= endB && endB <= endA) {
				return true;
			}

			if (startB < startA && endA < endB) {
				return true;
			}

			return false;
		},

		dateOverlapsWith() {
			if (!this.terminal) {
				return;
			}

			const agreementTerminals = this.terminal.agreementTerminals.filter(
				(agreementTerminal) => agreementTerminal.agreementId != this.agreementTerminal.agreementId
			);

			for (const agreementTerminal of agreementTerminals) {
				const from = new Date(agreementTerminal.from);
				const to = new Date(agreementTerminal.to);

				const [startA, endA, startB, endB] = [
					new Date(this.agreementTerminal.from).getTime(),
					new Date(this.agreementTerminal.to).getTime(),
					from.getTime(),
					to.getTime(),
				];

				const overlap = this.dateOverlaps(startA, endA, startB, endB);

				if (overlap) {
					return agreementTerminal.agreement;
				}
			}

			return null;
		},

		edit() {
			this.loading = true;

			axios
				.patch(`/agreements/agreement-terminal/${this.agreementTerminal.id}`, {
					from: this.agreementTerminal.from,
					to: this.agreementTerminal.to || null,
				})
				.catch((error) => {
					console.error('Failed to update agreementTerminal period', error.response);
				})
				.finally(() => {
					this.loading = false;

					this.$emit('close');
				});
		},

		close() {
			this.$emit('close');
		},
	},

	validations: {
		email: {
			email,
		},
	},
};
</script>

<style lang="scss" scoped>
.flex span {
	width: 100%;
}

.date-input {
	position: relative;

	.date-input-icon {
		position: absolute;
		right: 24px;
		top: 50%;
		transform: translateY(-50%);

		font-size: 18px;

		color: var(--color-grey);

		cursor: pointer;
	}

	.date-input-icon-now {
		position: absolute;
		right: 48px;
		top: 50%;
		transform: translateY(-50%);

		font-size: 18px;

		color: var(--color-grey);

		cursor: pointer;
	}
}
</style>
