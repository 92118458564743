var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-overlay',{attrs:{"visible":_vm.visible},on:{"close":_vm.close}},[_c('div',{staticClass:"flex space-between align-center mb-50"},[_c('div',[_c('h1',{staticClass:"mb-10"},[_vm._v("Tíðarskeið")])]),_c('div',{staticClass:"overlay-close",on:{"click":function($event){$event.preventDefault();_vm.$v.$reset();
				_vm.close();}}})]),_c('div',{staticClass:"grid grid-2 grid-gap-20 mb-20"},[(_vm.agreementTerminal)?_c('v-date-picker',{attrs:{"mode":"dateTime","is24hr":"","minute-increment":5},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var inputValue = ref.inputValue;
				var inputEvents = ref.inputEvents;
return [_c('div',[_c('label',{staticClass:"block mb-10",attrs:{"for":"from"}},[_vm._v("Avtala byrjar")]),_c('div',{staticClass:"date-input"},[_c('input',_vm._g({attrs:{"type":"text"},domProps:{"value":inputValue}},inputEvents)),_c('div',{staticClass:"date-input-icon-now",attrs:{"title":"Nú"},on:{"click":function($event){_vm.agreementTerminal.from = new Date()}}},[_c('i',{staticClass:"fa fa-clock"})]),(!_vm.agreementTerminal.from)?_c('div',{staticClass:"date-input-icon"},[_c('i',{staticClass:"fa fa-calendar-alt"})]):_vm._e(),(_vm.agreementTerminal.from)?_c('div',{staticClass:"date-input-icon",on:{"click":function($event){_vm.agreementTerminal.from = null}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e()])])]}}],null,false,3181763123),model:{value:(_vm.agreementTerminal.from),callback:function ($$v) {_vm.$set(_vm.agreementTerminal, "from", $$v)},expression:"agreementTerminal.from"}}):_vm._e(),(_vm.agreementTerminal)?_c('v-date-picker',{attrs:{"mode":"dateTime","is24hr":"","minute-increment":5,"min-date":_vm.agreementTerminal.from ? _vm.agreementTerminal.from : new Date()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var inputValue = ref.inputValue;
				var inputEvents = ref.inputEvents;
return [_c('div',[_c('label',{staticClass:"block mb-10",attrs:{"for":"from"}},[_vm._v("Avtala endar")]),_c('div',{staticClass:"date-input"},[_c('input',_vm._g({attrs:{"type":"text"},domProps:{"value":inputValue}},inputEvents)),_c('div',{staticClass:"date-input-icon-now",attrs:{"title":"Nú"},on:{"click":function($event){_vm.agreementTerminal.to = new Date()}}},[_c('i',{staticClass:"fa fa-clock"})]),(!_vm.agreementTerminal.to)?_c('div',{staticClass:"date-input-icon"},[_c('i',{staticClass:"fa fa-calendar-alt"})]):_vm._e(),(_vm.agreementTerminal.to)?_c('div',{staticClass:"date-input-icon",on:{"click":function($event){_vm.agreementTerminal.to = null}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e()])])]}}],null,false,615309471),model:{value:(_vm.agreementTerminal.to),callback:function ($$v) {_vm.$set(_vm.agreementTerminal, "to", $$v)},expression:"agreementTerminal.to"}}):_vm._e()],1),(_vm.dateOverlapsWith())?_c('div',{staticClass:"color-red text-center"},[_vm._v(" Hetta yvirlappar við "),_c('router-link',{staticClass:"color-red",staticStyle:{"font-weight":"700"},attrs:{"to":{ name: 'AgreementShow', params: { id: _vm.dateOverlapsWith().id } }}},[_vm._v("hesa avtaluna")]),_vm._v(". ")],1):_vm._e(),_c('div',{staticClass:"flex justify-end"},[_c('div',{staticClass:"btn inline-block",class:{ disabled: _vm.loading },on:{"click":_vm.edit}},[_vm._v(_vm._s(_vm.loading ? 'Bíða við...' : 'Rætta'))])])])}
var staticRenderFns = []

export { render, staticRenderFns }